export const ROUTES = {
  AUTH: '',

  ADMIN_COMPANIES: 'companies-admin',
  ADMIN_LICENSES: 'licenses-admin',
  ADMIN_USERS: 'users-admin',

  USER_LICENSES: 'licenses-user',
  USER_USERS: 'users-user',
  USER_FILES: 'files-user',
}
