import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from './modal.service';
import { License } from '../models/license';
import { ModalType } from '../models/modal-type.enum';
import { LicenseService } from '../services/license.service';

@Component({
  selector: 'app-modal-request',
  template: `
    <p-dialog
      header="Upload license request"
      [modal]="true"
      *ngIf="visible"
      [(visible)]="visible">
      <div class="p-fluid">
        <div class="p-grid">
          <div class="p-col">
            <div class="p-field">
              <textarea
                [rows]="18"
                [cols]="65"
                pInputTextarea
                style="font-family: 'Courier New', Courier, monospace"
                placeholder="Paste your license request"
                [(ngModel)]="request"></textarea>
            </div>
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <button pButton (click)="visible = false" class="p-button-secondary">Close</button>
        <button pButton autofocus (click)="licenseRequest()">Send</button>
      </ng-template>
    </p-dialog>
  `
})
export class ModalRequestComponent implements OnInit, OnDestroy {
  sub: Subscription
  visible = false
  license: License
  request = ''
  requestTooltip = `
-----BEGIN CERTIFICATE REQUEST-----
MIIC0zCCAbsCAQAwgY0xCzAJBgNVBAYTAlBMMQwwCgYDVQQIDANNQVoxDDAKBgNV
BAcMA1dBVzEPMA0GA1UECgwGUGFzc3VzMRcwFQYDVQQLDA5TTjIyMDg3MTAwOTQ1
MjESMBAGA1UEAwwJUGFzc3VzREVWMSQwIgYJKoZIhvcNAQkBFhVhZGFtLmd1emVr
QHBhc3N1cy5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCTPSny
7JhvIaeOo/36DQseRUW0Q/XajWqAYSoF+fEGQUeBvou/8dKxEJ8QiOQBw96XRyIi
FsbG4Hj1qDuPRoUdJqhQQUZm7Q/NHvEVEkNlkMvCCjbV5xRfzhAbPrkwtOSkwNid
jJ30xKbTLX4pdTJS9Npz2JdwDTAQb3t1NjtDogvClQhUkfKRilk19CP7Q+F9Cei5
GQm5msl5doTTnr2qKs+ZS1e8jnX9wHSjUsQMhQsaelzgHadTjfFyuMsBImX8ejEc
g5NwKy0bEDujqZZZRsE9kXYwwOcZ4EIms2jisNdw0IUCZhzhIbr6jurDHq6vneny
It9D0yHC1s3RANJTAgMBAAGgADANBgkqhkiG9w0BAQsFAAOCAQEAgddVz4Z4AuW8
mUOUJGotadqJFk5+W6AfcFwkFuhKBjyU3cqOgZDlGqoNstak4cNIdXnMlXdbUMoe
kikZxDHF2hsai8iO84ItGOYyn2usdFNlhpr7yZcXmRgHmfSZEBzTasFV1vj4pmdO
bfdIGmh3cdRFXE7YVQKd9VYbN/YGMPQCaqH9AoaT2vxSiqj/D8I+paKQ5GyE9qjG
18oEAd1pxXBvSnksOcaCN2JibHLijjKzx83/9DLrl10ef6UumxwxbU/9VWK+/hKS
m5ZYQvjWdbeIpiYM/+0gwqD09gTyD1bY6hK1jEz44Ez80ouDkEUNU5Zt9sPZZZNF
i0/uab5/3w==
-----END CERTIFICATE REQUEST-----`

  constructor(
    public licenseService: LicenseService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.sub = this.modalService.subject.subscribe(res => {
      if (res.type === ModalType.REQUEST) {
        this.license = res.license;
        this.visible = true;
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  licenseRequest() {
    this.licenseService.request(this.license.license_sn, this.request)
      .then(() => {
        this.license = null;
        this.visible = false;
      })
  }
}
