import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ModalService } from './modal.service';
import { Company, companyInit } from '../models/company';
import { ModalType } from '../models/modal-type.enum';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-modal-company',
  template: `
    <form *ngIf="visible" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <p-dialog
        header="Add company"
        [modal]="true"
        [(visible)]="visible">
        <div class="p-fluid">
          <div class="p-grid">
            <div class="p-col">
              <div class="p-field">
                <label>Name</label>
                <input pInputText formControlName="name">
              </div>
              <div class="p-field">
                <label>Street</label>
                <input pInputText formControlName="street">
              </div>
              <div class="p-field">
                <label>Postal Code</label>
                <input pInputText formControlName="postal_code">
              </div>
              <div class="p-field">
                <label>City</label>
                <input pInputText formControlName="city">
              </div>
              <div class="p-field">
                <label>State or province</label>
                <input pInputText formControlName="state_or_province">
              </div>
              <div class="p-field">
                <label>Country</label>
                <input pInputText formControlName="country">
              </div>
            </div>
            <div class="p-col">
              <div class="p-field">
                <label>Domain</label>
                <input pInputText formControlName="domain">
              </div>
              <div class="p-field">
                <label>Admin Name</label>
                <input pInputText formControlName="client_admin_name">
              </div>
              <div class="p-field">
                <label>Admin Surname</label>
                <input pInputText formControlName="client_admin_surname">
              </div>
              <div class="p-field">
                <label>Admin Email</label>
                <input pInputText formControlName="client_admin_email">
              </div>
              <div class="p-field">
                <label>Admin Phone</label>
                <input pInputText formControlName="client_admin_phone">
              </div>
            </div>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <button type="button" pButton (click)="visible = false" class="p-button-secondary" label="Close without saving"></button>
          <button type="submit" pButton [disabled]="!formGroup.valid" [loading]="loading" label="Save company"></button>
        </ng-template>
      </p-dialog>
    </form>
  `
})
export class ModalCompanyComponent implements OnInit, OnDestroy {
  sub: Subscription;
  visible: boolean
  company: Company
  loading = false;
  editMode = false;
  formGroup: FormGroup;

  constructor(
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.sub = this.modalService.subject.subscribe(res => {
      if (res.type === ModalType.COMPANY_ADD || res.type === ModalType.COMPANY_EDIT) {
        this.editMode = res.type === ModalType.COMPANY_EDIT;
        this.company = this.editMode ? res.company : companyInit;
        this.formGroup = this.formBuilder.group({
          'name': [this.company.name, Validators.required],
          'street': [this.company.street, Validators.required],
          'postal_code': [this.company.postal_code, Validators.required],
          'city': [this.company.city, Validators.required],
          'state_or_province': [this.company.state_or_province, Validators.required],
          'country': [this.company.country, Validators.required],
          'domain': [this.company.domain, Validators.required],
          'client_admin_name': [this.company.client_admin_name, Validators.required],
          'client_admin_surname': [this.company.client_admin_surname, Validators.required],
          'client_admin_email': [this.company.client_admin_email, [Validators.required, Validators.email]],
          'client_admin_phone': [this.company.client_admin_phone, Validators.required],
        })
        this.visible = true;
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onSubmit() {
    this.loading = true;
    const promise = this.editMode
      ? this.companyService.update({ id: this.company.id, ...this.formGroup.value })
      : this.companyService.add(this.formGroup.value);
    promise.then(() => {
        this.loading = false;
        this.visible = false;
      })
      .catch(() => {
        this.loading = false;
      })
  }
}
