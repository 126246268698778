import { Component, OnInit } from '@angular/core';

import { Company } from '../models/company';
import { ModalType } from '../models/modal-type.enum';
import { ModalService } from '../modal/modal.service';
import { CompanyService } from '../services/company.service';
import { LicenseService } from '../services/license.service';
import { CompanyState } from '../models/company-state.enum';

@Component({
  selector: 'app-admin-companies',
  template: `
    <p-table
      #companyTable
      dataKey="id"
      [value]="companyService.companies"
      [loading]="companyService.loading"
      [globalFilterFields]="['name','domain','client_admin_email','street','postal_code','country','status']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <button pButton (click)="companyAdd()">Add Company</button>
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="companyTable.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3.5rem"></th>
          <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
          <th pSortableColumn="domain">Domain <p-sortIcon field="domain"></p-sortIcon></th>
          <th pSortableColumn="client_admin_email">Email <p-sortIcon field="client_admin_email"></p-sortIcon></th>
          <th pSortableColumn="street">Street <p-sortIcon field="street"></p-sortIcon></th>
          <th style="width: 6rem" pSortableColumn="postal_code">Code <p-sortIcon field="postal_code"></p-sortIcon></th>
          <th pSortableColumn="state_or_province">State <p-sortIcon field="state_or_province"></p-sortIcon></th>
          <th style="width: 7rem" pSortableColumn="country">Country <p-sortIcon field="country"></p-sortIcon></th>
          <th style="width: 8rem" pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
          <th style="width: 7rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10" class="p-p-6">There are no compaines, add new one!</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="10" class="p-p-6">Fetching companies data...</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-company let-expanded="expanded">
        <tr>
          <td>
            <button
              pButton pRipple type="button"
              [pRowToggler]="company"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>{{company.name}}</td>
          <td>{{company.domain}}</td>
          <td>{{company.client_admin_email}}</td>
          <td>{{company.street}}</td>
          <td>{{company.postal_code}}</td>
          <td>{{company.state_or_province}}</td>
          <td>{{company.country}}</td>
          <td>{{company.status}}</td>
          <td class="p-text-right">
            <button
              icon="pi pi-pencil"
              pTooltip="Edit company"
              (click)="companyEdit(company)"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-outlined p-mr-2"></button>
            <button
              *ngIf="company.status === state.ACTIVATED"
              icon="pi pi-ban"
              pTooltip="Deactivate company"
              (click)="companyDeactivate(company)"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-danger p-button-outlined p-mr-2"></button>
            <button
              *ngIf="company.status !== state.ACTIVATED"
              icon="pi pi-check-circle"
              pTooltip="Activate company"
              (click)="companyActivate(company)"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-success p-button-outlined p-mr-2"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-company>
        <tr>
          <td colspan="10" style="padding: 1rem 0 5rem 3.5rem">
            <app-table-license
              [isAdmin]="true"
              [isAdding]="true"
              [company]="company"
              [loading]="licenseService.loading"
              [licenses]="licenseService.filterByCompany(company)"></app-table-license>
          </td>
        </tr>
      </ng-template>
    </p-table>
  `,
  styles: []
})
export class AdminCompaniesComponent implements OnInit {
  state = CompanyState;

  constructor(
    public companyService: CompanyService,
    public licenseService: LicenseService,
    public modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.companyService.get();
    this.licenseService.get();
  }

  companyAdd() {
    this.modalService.subject.next({ type: ModalType.COMPANY_ADD })
  }

  companyEdit(company: Company) {
    this.modalService.subject.next({ company, type: ModalType.COMPANY_EDIT })
  }

  companyActivate(company: Company) {
    this.modalService.subject.next({ company, type: ModalType.COMPANY_ACTIVATE })
  }

  companyDeactivate(company: Company) {
    this.modalService.subject.next({ company, type: ModalType.COMPANY_DEACTIVATE })
  }
}
