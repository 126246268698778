import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { MessageService } from "primeng/api";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ModalService } from "../modal/modal.service";

interface IParams {
  [key: string]: string | number | boolean
}

enum Method {
  get = "get",
  put = "put",
  post = "post",
  patch = "patch",
  delete = "delete",
}

@Injectable({
  providedIn: 'root'
})
export class APIService {
  private options = {
    responseType: "json",
    headers: {
      authorization: ''
    },
  }

  public URL = {
    LICENSE_GET: '/license/portal/licenses?companyId=:companyId',
    LICENSE_ADD: '/license/portal/initLicense',
    LICENSE_UPDATE: '/license/portal/license?licenseSN=:licenseSN',
    LICENSE_CSR:    '/license/portal/addClientToLicense?licenseSN=:licenseSN',
    LICENSE_COMMIT: '/license/portal/commitLicense?licenseSN=:licenseSN',
    LICENSE_REJECT: '/license/portal/reject?licenseSN=:licenseSN',
    LICENSE_DISABLE: '/license/portal/disableLicense?licenseSN=:licenseSN',
    LICENSE_TOKEN:  '/license/portal/activateKey?licenseSN=:licenseSN',
    LICENSE_REMOVE: '/license/portal/remove?licenseSN=:licenseSN',

    MODULE_ADD: '/license/portal/licenseAppModule?licenseSN=:licenseSN',
    MODULE_REMOVE: '/license/portal/licenseAppModule?licenseSN=:licenseSN&appModuleId=:appModuleId',

    FUNCTION_ADD: '/license/portal/licenseAppModuleFunction?licenseSN=:licenseSN&appModuleId=:appModuleId',
    FUNCTION_REMOVE: '/license/portal/licenseAppModuleFunction?licenseSN=:licenseSN&appModuleId=:appModuleId&functionName=:functionName',

    LICENSE_TYPES: '/license/portal/dictLicenseTypes',
    CLIENT_TYPES: '/license/portal/dictClientTypes',
    LICENSE_SIZES: '/license/portal/dictLicenseSizes',
    MODULES_DEFS: '/license/portal/dictAppModulesDefs',
    REPOS_DEFS: '/license/portal/dictRepos',

    COMPANY: `/${ environment.ENV }/companies`,
    COMPANY_INFO: `/${ environment.ENV }/company`,
    COMPANY_EDIT: `/${ environment.ENV }/companies/:companyId`,
    COMPANY_ACTIVATE: `/${ environment.ENV }/companies/:companyId/activate`,
    COMPANY_DEACTIVATE: `/${ environment.ENV }/companies/:companyId/deactivate`,

    USER_ADD:    `/${ environment.ENV }/invitations`,
    USER_GET:    `/${ environment.ENV }/users`,
    USER_REMOVE: `/${ environment.ENV }/users/:userId`,


    FILE_GET: `/${ environment.ENV }/files/software`,
    FILE_PRESIGNED: `/${ environment.ENV }/files/software/presigned`,
  }

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private messageService: MessageService,
  ) { }

  get(url: string, params: IParams = {}) {
    return this.process(Method.get, url, params)
  }

  put(url: string, body: any = {}, params = {}) {
    return this.process(Method.put, url, params, body)
  }

  post(url: string, body: any = {}, params = {}) {
    return this.process(Method.post, url, params, body)
  }

  patch(url: string, body: any = {}, params = {}) {
    return this.process(Method.patch, url, params, body)
  }

  delete(url: string, params = {}) {
    return this.process(Method.delete, url, params)
  }

  private async process(method: Method, url: string, params: IParams, body?: any) {
    const endpoint = this.resolve(url, params);
    // TODO: simplify logic below
    this.options.headers.authorization = `Bearer ${ (await Auth.currentSession()).getIdToken().getJwtToken() }`
    // @ts-expect-error
    return this.http[method](endpoint, body ? body : this.options, body ? this.options : undefined)
      .pipe(
        // TODO: some global error handler
        catchError((err: any) => {
          this.modalService.error({
            summary: 'Connection error',
            detail: err.error ? err.error.message : err.message,
          });
          return throwError(err);
        }),
        // finalize(() => {
        //   // trigger change detection
        //   // because ngZone is off
        //   setTimeout(() => this.$rootScope.$apply(), 100);
        // })
      )
      .toPromise()
      .then(res => {
        if (method !== Method.get) {
          this.modalService.success({
            summary: 'Success',
            detail: 'Request processed correctly.',
          })
        }
        return res;
      })
      .catch(err => console.log('Catched error', err))
  }

  // resolve maps params to route and append search params
  // input: url: '/api/users/:userId, params: { userId: 12, project: 13 }
  // output: url: '/api/users/12?project=13
  private resolve(url: string, params: IParams): string {
    const search = [];
    for (let key in params) {
      const routeParam = `:${ key }`;
      if (url.includes(routeParam)) {
        url = url.replace(routeParam, params[key].toString());
      } else {
        search.push([key, params[key].toString()]);
      }
    }
    if (url.includes(':')) {
      throw new Error('Missing params for ' + url + ' ' + JSON.stringify(params));
    };
    const urlWithQuery = url + (search.length > 0 ? ('?' + (new URLSearchParams(search)).toString()) : '');
    return environment.API + urlWithQuery;
  }
};
