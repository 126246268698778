<div class="layout-menu-wrapper" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}"
     (click)="appMain.onSidebarClick($event)" (mouseover)="appMain.onSidebarMouseOver($event)" (mouseleave)="appMain.onSidebarMouseLeave($event)">
    <div class="menu-logo" *ngIf="!appMain.isHorizontal() || appMain.isMobile()">

      <a href="#" class="logo">
        <img [src]="'assets/sycope/sycope-logo.svg'" style="height: 30px">
      </a>

        <!-- <a href="#" class="logo">
            <img [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'">
        </a> -->

        <!-- <a href="#" class="app-name" *ngIf="!appMain.isSlim() || appMain.isMobile()">
            <img [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"/>
        </a> -->
        <!-- <a href="#" class="menu-pin" (click)="appMain.onToggleMenu($event)">
            <span *ngIf="appMain.isOverlay()" class="pi pi-times"></span>
            <span *ngIf="appMain.isSidebar() && !appMain.sidebarStatic && appMain.pinActive" class="pi pi-unlock"></span>
            <span *ngIf="appMain.isSidebar() && appMain.sidebarStatic && appMain.pinActive" class="pi pi-lock"></span>
        </a> -->
    </div>

    <div class="layout-menu-container">
        <ul class="layout-menu">
            <ng-container *ngFor="let item of model; let i = index;">
                <li app-menuitem *ngIf="!item.separator" [item]="item" [index]="i" [root]="true"></li>
                <li *ngIf="item.separator" class="menu-separator"></li>
            </ng-container>
        </ul>
    </div>

    <app-inlinemenu></app-inlinemenu>
</div>
