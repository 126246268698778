import { AppFunction, appFunctionInit } from "./app-function"

export interface AppModule {
  id?: string
  module_def: string
  trial: boolean
  enabled_functions?: AppFunction[]
}

export const appModuleInit = {
  id: "Netflow",
  trial: false,
  module_def: "Netflow",
  enabled_functions: [appFunctionInit]
} as AppModule;
