import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from './modal.service';
import { License } from '../models/license';
import { ModalType } from '../models/modal-type.enum';
import { LicenseService } from '../services/license.service';

@Component({
  selector: 'app-license-modal',
  template: `
    <p-dialog
      header="Add license"
      [modal]="true"
      *ngIf="visible"
      [(visible)]="visible">
      <div class="p-fluid">
        <div class="p-grid">
          <div class="p-col-fixed" style="width: 250px">
            <div class="p-field">
              <label>Company ID</label>
              <input pInputText disabled="true" [(ngModel)]="license.license_sn">
            </div>
            <div class="p-field">
              <label>Client type</label>
              <p-dropdown
                [options]="licenseService.clientsOptions"
                [(ngModel)]="license.client_type"
                placeholder="Select client type"
                optionLabel="name"
                optionValue="name"
                (onChange)="changed = true"></p-dropdown>
            </div>
            <div class="p-field">
              <label>License size</label>
              <p-dropdown
                [options]="licenseService.sizesOptions"
                [(ngModel)]="license.size"
                placeholder="Select license size"
                optionLabel="name"
                optionValue="name"
                (onChange)="changed = true"></p-dropdown>
            </div>
            <div class="p-field">
              <label>License type</label>
              <p-dropdown
                class="w-max"
                [options]="licenseService.typesOptions"
                [(ngModel)]="license.type"
                placeholder="Select license type"
                optionLabel="name"
                optionValue="name"
                (onChange)="changed = true"></p-dropdown>
            </div>
            <div class="p-field">
              <div class="p-field-checkbox">
                <p-checkbox
                  inputId="online"
                  binary="true"
                  [(ngModel)]="license.online"
                  (onChange)="changed = true"></p-checkbox>
                <label for="online">Online</label>
              </div>
            </div>
            <div class="p-field-checkbox">
              <p-checkbox
                inputId="consent"
                binary="true"
                [(ngModel)]="license.consent"
                (onChange)="changed = true"></p-checkbox>
              <label for="consent">Consent</label>
            </div>
            <button
              pButton pRipple type="button"
              label="Advanced"
              (click)="showAdvanced = !showAdvanced"
              class="p-mb-3 p-button-text p-button-rounded p-button-plain"
              [icon]="showAdvanced ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            <div *ngIf="showAdvanced" class="p-field">
              <label>Repository</label>
              <p-dropdown
                class="w-max"
                [options]="licenseService.reposOptions"
                [(ngModel)]="license.repo_branch"
                placeholder="Select a repository"
                optionLabel="name"
                optionValue="name"
                (onChange)="changed = true"></p-dropdown>
            </div>
            <div class="p-field">
              <label>Description (visible for clients)</label>
              <textarea
                pInputTextarea
                [(ngModel)]="license.description"
                (input)="changed = true"></textarea>
            </div>
            <div class="p-d-flex">
              <button pButton (click)="visible = false" class="p-button-secondary p-mr-2" label="Close"></button>
              <button pButton (click)="licenseUpdate()" [disabled]="!changed" [loading]="loading" label="Save"></button>
            </div>
          </div>
          <div class="p-col-fixed" style="width: 650px">
            <app-modal-module
              [changed]="changed"
              [license]="license"></app-modal-module>
          </div>
        </div>
      </div>
    </p-dialog>
  `
})
export class LicenseModalComponent implements OnInit, OnDestroy {
  sub: Subscription
  license: License
  visible = false;
  changed = false;
  loading = false;
  showAdvanced = false;

  constructor(
    public licenseService: LicenseService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.sub = this.modalService.subject.subscribe(res => {
      if (res.type === ModalType.LICENSE) {
        this.license = JSON.parse(JSON.stringify(res.license));
        this.visible = true;
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  licenseUpdate() {
    this.loading = true;
    this.licenseService.update(this.license.license_sn, this.license)
      .then(license => {
        this.license = license;
        this.loading = false;
        this.changed = false;
      });
  }

}
