import { License } from "./license";

export interface Company {
  id?: string
  name: string
  street: string
  postal_code: string
  state_or_province: string
  country: string
  city: string
  domain: string
  client_admin_name: string
  client_admin_surname: string
  client_admin_phone: string
  client_admin_email: string

  valid_to?: string
  licenses?: License[]
}

export const companyInit = {
  name: "",
  street: "",
  postal_code: "",
  country: "",
  city: "",
  state_or_province: "",
  domain: "",
  client_admin_name: "",
  client_admin_surname: "",
  client_admin_phone: "",
  client_admin_email: "",
  licenses: []
};

export const companyMap = {
  admin_email: 'client_admin_email',
  common_name_CN: 'name',
  organization_O: 'id',
  locality_L: 'city',
  state_or_province_ST: 'state_or_province',
  country_C: 'country',
}
