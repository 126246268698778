// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  API: 'https://lic-dev.sycope.com',
  ENV: 'dev',
  AWS: {},
  USER: {
    aws_project_region: "eu-central-1",
    aws_cognito_identity_pool_id: "eu-central-1:0cda24b3-9a27-413c-8a44-961c40752bbc",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_jjEH5GirK",
    aws_user_pools_web_client_id: "38grvbouo5covoubvi00f71nd6",
  },
  ADMIN: {
    aws_project_region: "eu-central-1",
    aws_cognito_identity_pool_id: "eu-central-1:0cda24b3-9a27-413c-8a44-961c40752bbc",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_jjEH5GirK",
    aws_user_pools_web_client_id: "38grvbouo5covoubvi00f71nd6",
  }
};

environment.AWS = localStorage.getItem('isAdmin') ? environment.ADMIN : environment.USER;
