import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppFunction, appFunctionEmpty } from '../models/app-function';
import { AppModule } from '../models/app-module';
import { License } from '../models/license';
import { ModalType } from '../models/modal-type.enum';
import { LicenseService } from '../services/license.service';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal-function',
  template: `
    <p-dialog
      header="Add function"
      [modal]="true"
      [style]="{width: '320px'}"
      *ngIf="visible"
      [(visible)]="visible">
      <div class="p-fluid">
        <div class="p-field">
          <label>Function name</label>
          <p-dropdown
            [options]="licenseService.functionsOptions[module.module_def]"
            [(ngModel)]="function.name"
            placeholder="Select a function"
            optionLabel="name"
            optionValue="name"></p-dropdown>
        </div>
        <div class="p-field">
          <label>Valid From</label>
          <p-calendar
            dateFormat="yy/m/d"
            (onSelect)="onSelectValidFrom($event)"
            [ngModel]="valid_from"
            appendTo="body"></p-calendar>
        </div>
        <div class="p-field">
          <label>Valid To</label>
          <p-calendar
            view="month"
            dateFormat="yy/m/d"
            (onSelect)="onSelectValidTo($event)"
            [disabled]="!valid_from"
            [ngModel]="valid_to"
            appendTo="body"></p-calendar>
        </div>
        <div class="p-field-checkbox">
          <p-checkbox inputId="trial" binary="true" [(ngModel)]="function.trial"></p-checkbox>
          <label for="trial">Trial</label>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <button pButton (click)="visible = false" class="p-button-secondary">Close</button>
        <button pButton autofocus (click)="functionAdd()">Save</button>
      </ng-template>
    </p-dialog>
  `
})
export class ModalFunctionComponent implements OnInit, OnDestroy {
  sub: Subscription;
  visible = false;
  module: AppModule
  function: AppFunction
  license: License
  valid_from: Date = new Date();
  valid_to: Date = new Date();

  constructor(
    public modalService: ModalService,
    public licenseService: LicenseService,
  ) { }

  ngOnInit(): void {
    this.sub = this.modalService.subject.subscribe(res => {
      if (res.type === ModalType.FUNCTION) {
        this.module = res.module;
        this.license = res.license;
        this.function = appFunctionEmpty;
        this.visible = true;
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  getDate(date: Date): [ number, number, number ] {
    return [ date.getFullYear(), date.getMonth() + 1, date.getDate() ];
  }

  onSelectValidTo(value: Date) {
    this.valid_to = new Date(value.getFullYear(), value.getMonth(), this.valid_from.getDate())
  }

  onSelectValidFrom(value: Date) {
    this.valid_from = value;
    if (this.valid_to) {
      this.valid_to = new Date(this.valid_to.getFullYear(), value.getMonth(), this.valid_from.getDate());
    }
  }

  functionAdd() {
    this.function.valid_from = this.getDate(this.valid_from);
    this.function.valid_to = this.getDate(this.valid_to);
    this.licenseService.functionAdd(this.license.license_sn, this.module.id, this.function)
      .then(license => {
        this.modalService.subject.next({
          type: ModalType.LICENSE,
          license,
        })
        this.visible = false
      });
  }

}
