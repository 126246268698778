import {Component, OnInit} from '@angular/core';
import { Auth } from 'aws-amplify';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import { ROUTES } from './app/routes';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    public model: any[] = [];

  constructor(public app: AppComponent, public appMain: AppMainComponent) {}

  ngOnInit() {
    Auth.currentAuthenticatedUser()
    .then(user => {
      const groups = user.signInUserSession.idToken.payload['cognito:groups']
      if (groups && groups.includes('admins')) {
        this.model = [
          {
            label: 'Companies',
            icon: 'pi pi-briefcase',
            routerLink: [ROUTES.ADMIN_COMPANIES],
          },
          {
            label: 'Licenses',
            icon: 'pi pi-shield',
            routerLink: [ROUTES.ADMIN_LICENSES],
          },
          {
            label: 'Users',
            icon: 'pi pi-users',
            routerLink: [ROUTES.ADMIN_USERS],
          },
        ];
      }
      if (groups && groups.includes('user-admins')) {
        this.model = [
          {
            label: 'Licenses',
            icon: 'pi pi-shield',
            routerLink: [ROUTES.USER_LICENSES],
          },
          {
            label: 'Users',
            icon: 'pi pi-users',
            routerLink: [ROUTES.USER_USERS],
          },
          {
            label: 'Files',
            icon: 'pi pi-file',
            routerLink: [ROUTES.USER_FILES],
          },
        ];
      }
    });
  }
}
