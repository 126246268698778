export interface AppFunction {
  name: string
  trial: boolean
  valid_from: [number, number, number]
  valid_to: [number, number, number]
}

export const appFunctionInit = {
  name: "Security",
  trial: true,
  valid_from: [ 2021, 11, 21 ],
  valid_to: [ 2022, 11, 21 ]
} as AppFunction;

export const appFunctionEmpty = {
  name: "",
  trial: true,
  valid_from: [ 2021, 11, 21 ],
  valid_to: [ 2022, 11, 21 ]
} as AppFunction
