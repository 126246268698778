import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AdminCompaniesComponent } from './app/admin/admin-companies.component';
import { AdminLicensesComponent } from './app/admin/admin-licenses.component';
import { AuthGuard } from './app/auth.guard';
import { UserLicensesComponent } from './app/user/user-licenses.component';
import { UserFilesComponent } from './app/user/user-files.component';
import { TableUserComponent } from './app/table-user.component';
import { ROUTES } from './app/routes';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '', component: AppLoginComponent},
        {
          path: '', component: AppMainComponent,
          children: [
            { path: ROUTES.ADMIN_COMPANIES, component: AdminCompaniesComponent, canActivate: [AuthGuard] },
            { path: ROUTES.ADMIN_LICENSES, component: AdminLicensesComponent, canActivate: [AuthGuard] },
            { path: ROUTES.ADMIN_USERS, component: TableUserComponent, canActivate: [AuthGuard] },
            { path: ROUTES.USER_LICENSES, component: UserLicensesComponent, canActivate: [AuthGuard] },
            { path: ROUTES.USER_USERS, component: TableUserComponent, canActivate: [AuthGuard] },
            { path: ROUTES.USER_FILES, component: UserFilesComponent, canActivate: [AuthGuard] },
          ]
        },
        {
          path: 'notfound', component: AppLoginComponent
        },
    ], {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
