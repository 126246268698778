import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  loading = false;
  users: User[] = [];

  constructor(private APIService: APIService) { }

  async get(force = true) {
    if (force || (this.users.length === 0 && !this.loading)) {
      this.loading = true;
      const res = await this.APIService.get(this.APIService.URL.USER_GET) as { output: User[] };
      this.users = res.output;
      this.loading = false;
    }
  }

  async add(email: string) {
    const res = await this.APIService.post(this.APIService.URL.USER_ADD, { email}) as Promise<{ output: string }>;
    return await this.get(true);
  }

  async remove(userId: string) {
    await this.APIService.delete(this.APIService.URL.USER_REMOVE, { userId }) as any
    return await this.get(true);
  }
}
