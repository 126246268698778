<div class="login-body">
  <div class="login-image">
    <!-- <img [src]="'assets/layout/images/pages/login-'+ (app.colorScheme === 'dark' ? 'ondark' : 'onlight') + '.png'" alt="atlantis"> -->
    <img [src]="'assets/sycope/sidebar.png'" alt="sycope">
  </div>
  <div class="login-panel p-fluid">
    <div class="p-d-flex p-flex-column">
      <div class="p-d-flex p-ai-center p-mb-6 logo-container">
        <!-- <img [src]="'assets/sycope/sycope-portal-logo.png'" class="login-logo"/> -->
        <img [src]="'assets/sycope/sycope-portal-logo.svg'" />
        <!-- <img [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="login-logo"/>
        <img [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="login-appname"/> -->
      </div>
      <app-auth></app-auth>
    </div>

    <div class="login-footer p-d-flex p-ai-center">
      <div class="p-d-flex p-ai-center login-footer-logo-container">
        <span>Sycope</span>
        <!-- <img src="assets/layout/images/logo-gray.png" class="login-footer-logo"/>
        <img src="assets/layout/images/appname-gray.png" class="login-footer-appname"/> -->
      </div>
      <span>Copyright 2022</span>
    </div>
  </div>
</div>
