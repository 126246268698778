export enum AppFunctionName {
  Visibility = "Visibility",
  Security = "Security",
  Performance = "Performance",
  TechnicalSupport = "TechnicalSupport",
  Updates = "Updates",
}

export const appFunctionNameMap = {
  [AppFunctionName.Visibility]:       'VIS',
  [AppFunctionName.Security]:         'SEC',
  [AppFunctionName.Performance]:      'PER',
  [AppFunctionName.TechnicalSupport]: 'SUP',
  [AppFunctionName.Updates]:          'SUP',
}
