import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../services/company.service';
import { LicenseService } from '../services/license.service';

@Component({
  selector: 'app-admin-licenses',
  template: `
    <app-table-license
      [isAdmin]="true"
      [isAdding]="false"
      [loading]="licenseService.loading"
      [licenses]="licenseService.licenses"></app-table-license>
  `
})
export class AdminLicensesComponent implements OnInit {

  constructor(
    public companyService: CompanyService,
    public licenseService: LicenseService,
  ) { }

  ngOnInit(): void {
    this.companyService.get();
    this.licenseService.get();
  }
}
