import { Component, Input, OnInit } from '@angular/core';
import { AppModule } from '../models/app-module';
import { License } from '../models/license';
import { ModalType } from '../models/modal-type.enum';
import { LicenseService } from '../services/license.service';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal-module',
  template: `
    <p-table dataKey="id" [value]="license.application_modules">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-end">
          <p-dropdown
            [options]="modulesOptions"
            [(ngModel)]="moduleDef"
            placeholder="Select a module"
            optionLabel="name"
            optionValue="name"></p-dropdown>
          <button
            pButton
            [disabled]="!moduleDef"
            (click)="moduleAdd()"
            label="Add module"
            style="width: 100px"
            class="p-ml-2"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3.5rem"></th>
          <th>Module</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">There are no modules for this license yet.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-module let-expanded="expanded">
        <tr>
          <td>
            <button
              pButton pRipple type="button"
              [pRowToggler]="module"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>{{ module.module_def }}</td>
          <td class="p-text-right">
            <button
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-success p-button-outlined p-mr-2"
              icon="pi pi-plus"
              pTooltip="Add function"
              (click)="functionAdd(module)"></button>
            <button
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-danger p-button-outlined p-mr-2"
              icon="pi pi-trash"
              pTooltip="Delete module"
              (click)="moduleRemove(module)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-module>
        <tr>
          <td colspan="3" style="padding: 1rem 0 1rem 3.5rem">
            <p-table [value]="module.enabled_functions" dataKey="name">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 14rem">Pricing</th>
                  <th>Name</th>
                  <th>Valid from</th>
                  <th>Valid to</th>
                  <th style="width: 5rem">Trial</th>
                  <th style="width: 5rem"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5">There are no functions for this module yet.</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-func>
                <tr>
                  <td>{{ licenseService.lic2price(license, func) }}</td>
                  <td>{{ func.name }}</td>
                  <td>{{ func.valid_from.join('/') }}</td>
                  <td>{{ func.valid_to.join('/') }}</td>
                  <td>{{ func.trial }}</td>
                  <td class="p-text-right">
                    <button
                      pButton pRipple type="button" tooltipPosition="bottom" class="p-button-danger p-button-outlined p-mr-2"
                      icon="pi pi-trash"
                      pTooltip="Delete function"
                      (click)="functionRemove(module.id, func.name)"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </td>
        </tr>
      </ng-template>
    </p-table>
  `
})
export class ModalModuleComponent implements OnInit {
  @Input() license: License;
  @Input() changed: boolean;
  moduleDef;
  modulesOptions = []

  constructor(
    private licenseService: LicenseService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.modulesOptions = this.licenseService.modulesOptions.slice();
  }

  moduleAdd() {
    if (this.changed) { return this.warn() }
    this.licenseService.moduleAdd(this.license.license_sn, this.moduleDef)
      .then(license => {
        this.modalService.subject.next({
          license,
          type: ModalType.LICENSE,
        })
      })
  }

  moduleRemove(module: AppModule) {
    if (this.changed) { return this.warn() }
    this.licenseService.moduleRemove(this.license.license_sn, module.id)
      .then(license => {
        this.modalService.subject.next({
          license,
          type: ModalType.LICENSE,
        })
      })
  }

  functionAdd(module: AppModule) {
    if (this.changed) { return this.warn() }
    this.modalService.subject.next({
      module,
      license: this.license,
      type: ModalType.FUNCTION,
    })
  }

  functionRemove(appModuleId: string, functionName: string) {
    if (this.changed) { return this.warn() }
    this.licenseService.functionRemove(this.license.license_sn, appModuleId, functionName)
      .then(license => {
        this.modalService.subject.next({
          license,
          type: ModalType.LICENSE,
        })
      })
  }

  warn() {
    this.modalService.warn({ summary: 'Save license first!', detail: 'Save the license data before editing the modules.' })
  }

}
