import { Injectable } from '@angular/core';

import { APIService } from './api.service';
import { License } from '../models/license';
import { AppFunction } from '../models/app-function';
import { Company } from '../models/company';
import { AuthService } from '../auth.service';
import { licenseSizeMap } from '../models/license-size.enum';
import { licenseTypeMap } from '../models/license-type.enum';
import { appFunctionNameMap } from '../models/app-function-name.enum';
import { clientTypeMap } from '../models/client-type.enum';

@Injectable({
  providedIn: 'root'
})
export class LicenseService  {
  loading = false;
  licenses: License[] = []

  licenseTypes: string[];
  clientTypes: string[];
  licenseSizes: ({ label: string, value: number })[];
  modulesDefs: ({ module_def: string, functions: string[] })[];
  reposDefs: string[];

  constructor(
    private APIService: APIService,
    private authService: AuthService,
  ) {
    this.APIService.get(this.APIService.URL.LICENSE_TYPES).then(res => this.licenseTypes = res as string[]);
    this.APIService.get(this.APIService.URL.CLIENT_TYPES).then(res => this.clientTypes = res as string[]);
    this.APIService.get(this.APIService.URL.LICENSE_SIZES).then(res => this.licenseSizes = res as ({ label: string, value: number })[]);
    this.APIService.get(this.APIService.URL.MODULES_DEFS).then(res => this.modulesDefs = res as ({ module_def: string, functions: string[] })[]);
    this.APIService.get(this.APIService.URL.REPOS_DEFS).then(res => this.reposDefs = res as string[]);
  }

  replaceBySN(licenseSN: string, license: License): License {
    const index = this.licenses.findIndex(license => license.license_sn === licenseSN);
    this.licenses[index] = license;
    return license;
  }

  removeBySN(licenseSN: string): License[] {
    this.licenses = this.licenses.filter(license => license.license_sn !== licenseSN);
    return this.licenses;
  }

  filterByCompany(company: Company): License[] {
    return this.licenses.filter(license => license.company_id === company.id);
  }

  /*
   * COMMON
   */
  async get(): Promise<License[]> {
    if (this.licenses.length === 0 && !this.loading) {
      this.loading = true;
      const user = await this.authService.currentAuthenticatedUser();
      const companyId = user.attributes['custom:companyId'] || 'all';
      this.licenses = await this.APIService.get(this.APIService.URL.LICENSE_GET, { companyId }) as License[];
      this.loading = false;
    }
    return this.licenses;
  }

  /*
   * ADMIN
   */
  async add(company_id: string): Promise<License> {
    const license = await this.APIService.post(this.APIService.URL.LICENSE_ADD, { company_id }) as License;
    this.licenses.push(license);
    return license;
  }

  async update(licenseSN: string, license: License): Promise<License> {
    const updated = await this.APIService.post(this.APIService.URL.LICENSE_UPDATE, license, { licenseSN }) as License;
    return this.replaceBySN(licenseSN, updated);
  }

  async commit(licenseSN: string): Promise<License> {
    const updated = await this.APIService.post(this.APIService.URL.LICENSE_COMMIT, {}, { licenseSN }) as License
    return this.replaceBySN(licenseSN, updated);
  }

  async reject(licenseSN: string): Promise<License> {
    const updated = await this.APIService.post(this.APIService.URL.LICENSE_REJECT, {}, { licenseSN }) as License;
    return this.replaceBySN(licenseSN, updated);
  }

  async remove(licenseSN: string): Promise<License[]> {
    const updated = await this.APIService.post(this.APIService.URL.LICENSE_REMOVE, {}, { licenseSN }) as License;
    console.log(updated)
    return this.removeBySN(licenseSN);
  }

  async disable(licenseSN: string): Promise<License> {
    const updated = await this.APIService.post(this.APIService.URL.LICENSE_DISABLE, {}, { licenseSN }) as License;
    return this.replaceBySN(licenseSN, updated);
  }

  /*
   * USER
   */
  async request(licenseSN: string, request: string): Promise<License> {
    const updated = await this.APIService.post(this.APIService.URL.LICENSE_CSR, request, { licenseSN }) as License;
    return this.replaceBySN(licenseSN, updated);
  }

  async token(licenseSN: string): Promise<string> {
    const token = await this.APIService.get(this.APIService.URL.LICENSE_TOKEN, { licenseSN }) as { key: string };
    return token.key
  }

  /*
   * MODULES
   */
  async moduleAdd(licenseSN: string, moduleDef: string): Promise<License> {
    const updated = await this.APIService.post(this.APIService.URL.MODULE_ADD, { moduleDef }, { licenseSN }) as License;
    return this.replaceBySN(licenseSN, updated);
  }

  async moduleRemove(licenseSN: string, appModuleId: string): Promise<License> {
    const updated = await this.APIService.delete(this.APIService.URL.MODULE_REMOVE, { licenseSN, appModuleId }) as License;
    return this.replaceBySN(licenseSN, updated);
  }

  /*
   * FUNCTIONS
   */
  async functionAdd(licenseSN: string, appModuleId: string, appFunction: AppFunction): Promise<License> {
    const updated = await this.APIService.post(this.APIService.URL.FUNCTION_ADD, appFunction, { licenseSN, appModuleId }) as License;
    return this.replaceBySN(licenseSN, updated);
  }

  async functionRemove(licenseSN: string, appModuleId: string, functionName: string): Promise<License> {
    const updated = await this.APIService.delete(this.APIService.URL.FUNCTION_REMOVE, { licenseSN, appModuleId, functionName }) as License;
    return this.replaceBySN(licenseSN, updated);
  }

  /*
   * DROPDOWNS
   */

  get typesOptions(): { name: string }[] {
    return this.licenseTypes.map(name => ({ name }));
  }

  get clientsOptions(): { name: string }[] {
    return this.clientTypes.map(name => ({ name }));
  }

  get sizesOptions(): { name: string }[] {
    return this.licenseSizes.map(({ label }) => ({ name: label }));
  }

  get modulesOptions(): { name: string }[] {
    return this.modulesDefs.map(({ module_def }) => ({ name: module_def }))
  }

  get reposOptions(): { name: string }[] {
    return this.reposDefs.map(name => ({ name }))
  }

  get functionsOptions(): { [key: string]: { name: string }[] } {
    const functions = {}
    this.modulesDefs.forEach(module => {
      functions[module.module_def] = module.functions.map(name => ({ name }))
    })
    return functions;
  }

  /*
   * UTILS
   */

  lic2price(license: License, func: AppFunction): string {
    let price = `${
      licenseTypeMap[license.type] || 'none'
    }-${
      appFunctionNameMap[func.name] || 'none'
    }-${
      licenseSizeMap[license.size] || 'none'
    }-${
      clientTypeMap[license.client_type] || 'none'
    }${
      func.trial ? '-TRIAL' : ''
    }`
    return price;
  }
}
