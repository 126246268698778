import { Client, clientInit } from "./client";
import { AppModule, appModuleInit } from "./app-module";

export interface License {
  application_modules: AppModule[]
  client?: Client
  client_access_key: string
  client_access_policy_arn: string
  client_admin_email: string
  client_certificate: string[]
  client_type: string
  company_id: string
  consent: boolean
  description: string
  flows_per_sec: number
  issuer_signature: string
  license_sn: string
  online: boolean
  size: string
  state: string
  type: string
  updated: string
}

// export const licenseInit = {
//   application_modules: [appModuleInit],
//   client:
// } as License;

export const licenseMap = [
  { label: 'Client type', value: 'client_type' },
  { label: 'License size', value: 'size' },
  { label: 'License type', value: 'type' },
  { label: 'Online', value: 'online' },
  { label: 'Consent', value: 'consent' },
  { label: 'Description', value: 'description' },
]

export const licenseInit = {
  client: clientInit,
  client_admin_email: "",
  license_sn: "",
  issuer: "",
  type: "",
  size: "",
  description: "",
  client_type: "",
  consent: false,
  online: false,
  flows_per_sec: null,
  application_modules: [appModuleInit],
  issuer_signature: "",
  client_certificate: [],
  client_access_key: "",
  client_access_policy_arn: "",
  valid_to: [],
  company_id: "",
  updated: "",
  state: "",
} as License
