import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from './modal.service';
import { Company, companyMap } from '../models/company';
import { License } from '../models/license';
import { ModalType } from '../models/modal-type.enum';
import { LicenseService } from '../services/license.service';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-modal-commit',
  template: `
    <p-dialog
      header="Commit license"
      [modal]="true"
      *ngIf="visible"
      [(visible)]="visible">
      <div class="p-fluid">
        <div class="p-grid">
          <div class="p-col">
            <h4>Company data</h4>
            <div class="p-field">
              <label>Admin email</label>
              <input pInputText disabled [class]="getClass('admin_email')" [ngModel]="getCompany('admin_email')">
            </div>
            <div class="p-field">
              <label>Common name</label>
              <input pInputText disabled [class]="getClass('common_name_CN')" [ngModel]="getCompany('common_name_CN')">
            </div>
            <div class="p-field">
              <label>Organization</label>
              <input pInputText disabled [class]="getClass('organization_O')" [ngModel]="getCompany('organization_O')">
            </div>
            <div class="p-field">
              <label>Locality</label>
              <input pInputText disabled [class]="getClass('locality_L')" [ngModel]="getCompany('locality_L')">
            </div>
            <div class="p-field">
              <label>State of province</label>
              <input pInputText disabled [class]="getClass('state_or_province_ST')" [ngModel]="getCompany('state_or_province_ST')">
            </div>
            <div class="p-field">
              <label>Country</label>
              <input pInputText disabled [class]="getClass('country_C')" [ngModel]="getCompany('country_C')">
            </div>
          </div>
          <div class="p-col">
            <h4>CSR data</h4>
            <div class="p-field">
              <label>Admin email</label>
              <input pInputText disabled [class]="getClass('admin_email')" [ngModel]="getClient('admin_email')">
            </div>
            <div class="p-field">
              <label>Common name</label>
              <input pInputText disabled [class]="getClass('common_name_CN')" [ngModel]="getClient('common_name_CN')">
            </div>
            <div class="p-field">
              <label>Organization</label>
              <input pInputText disabled [class]="getClass('organization_O')" [ngModel]="getClient('organization_O')">
            </div>
            <div class="p-field">
              <label>Locality</label>
              <input pInputText disabled [class]="getClass('locality_L')" [ngModel]="getClient('locality_L')">
            </div>
            <div class="p-field">
              <label>State of province</label>
              <input pInputText disabled [class]="getClass('state_or_province_ST')" [ngModel]="getClient('state_or_province_ST')">
            </div>
            <div class="p-field">
              <label>Country</label>
              <input pInputText disabled [class]="getClass('country_C')" [ngModel]="getClient('country_C')">
            </div>
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <button (click)="visible = false" label="Close" type="button" pButton class="p-button-secondary"></button>
        <button (click)="reject()" label="Reject" [loading]="loading" type="button" pButton class="p-button-secondary"></button>
        <button (click)="accept()" label="Accept" [loading]="loading" type="button" pButton></button>
      </ng-template>
    </p-dialog>
  `
})
export class ModalCommitComponent implements OnInit {
  sub: Subscription;
  visible: boolean;
  company: Company;
  license: License;
  loading = false

  constructor(
    private companyService: CompanyService,
    private licenseService: LicenseService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.sub = this.modalService.subject.subscribe(res => {
      if (res.type === ModalType.COMMIT) {
        this.license = res.license;
        this.company = this.companyService.findByLicense(this.license);
        if (!this.company) { alert(`Company with id ${this.license.company_id} doesn't exists!`) }
        this.visible = true;
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  accept() {
    this.loading = true;
    this.licenseService.commit(this.license.license_sn)
      .then(() => {
        this.loading = false;
        this.visible = false;
      })
  }

  reject() {
    this.loading = true;
    this.licenseService.reject(this.license.license_sn)
      .then(() => {
        this.loading = false;
        this.visible = false;
      })
  }

  getClient(key: string): string {
    return this.license.client[key];
  }

  getCompany(key: string): string {
    return this.company[companyMap[key]];
  }

  getClass(key: string): string {
    return this.license.client[key] !== this.company[companyMap[key]] ? 'ng-invalid ng-dirty' : '';
  }
}
