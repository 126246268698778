import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../services/company.service';
import { LicenseService } from '../services/license.service';

@Component({
  selector: 'app-user',
  template: `
    <app-table-license
      [isAdmin]="false"
      [isAdding]="false"
      [loading]="licenseService.loading"
      [licenses]="licenseService.licenses"
    ></app-table-license>
  `
})
export class UserLicensesComponent implements OnInit {

  constructor(
    public licenseService: LicenseService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.licenseService.get();
    this.companyService.getOne()
  }
}
