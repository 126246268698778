import { Component, OnInit } from '@angular/core';
import { ModalService } from './modal/modal.service';
import { ModalType } from './models/modal-type.enum';
import { User } from './models/user';
import { UsersService } from './services/users.service';

@Component({
  selector: 'app-table-user',
  template: `
    <p-table
      #userTable
      [value]="usersService.users"
      dataKey="clientId"
      [globalFilterFields]="['email', 'companyId']"
      [loading]="usersService.loading">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <button pButton (click)="userAdd()">Add user</button>
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="userTable.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>E-mail</th>
          <th>CompanyId</th>
          <!-- <th>name</th>
          <th>surname</th>
          <th>phone</th> -->
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>{{user.email}}</td>
          <td>{{user.companyId}}</td>
          <!-- <td>{{user.name}}</td>
          <td>{{user.surname}}</td>
          <td>{{user.phone}}</td> -->
          <td class="p-text-right">
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-trash"
              pTooltip="Delete user"
              tooltipPosition="bottom"
              (click)="userRemove(user)"
              class="p-button-danger p-button-outlined p-mr-2"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3" class="p-p-6">There are no users for your company yet.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="3" class="p-p-6">Fetching users...</td>
        </tr>
      </ng-template>
    </p-table>
  `
})
export class TableUserComponent implements OnInit {
  visible = false;
  email = "";

  constructor(
    public usersService: UsersService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.usersService.get()
  }

  userAdd() {
    this.modalService.subject.next({ type: ModalType.USER_ADD })
  }

  userRemove(user: User) {
    this.modalService.subject.next({ user, type: ModalType.USER_REMOVE })
  }
}
