import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

import { AppModule } from '../models/app-module';
import { Company } from '../models/company';
import { License } from '../models/license';
import { ModalType } from '../models/modal-type.enum';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public subject = new Subject<{
    type: ModalType,
    license?: License,
    company?: Company,
    message?: string,
    module?: AppModule,
    user?: User,
  }>();

  constructor(private messageService: MessageService) { }

  info({ detail, summary }) {
    this.messageService.add({
      detail,
      summary,
      key: 'toast',
      severity: 'info',
      closable: true,
    })
  }

  success({ detail, summary }) {
    this.messageService.add({
      detail,
      summary,
      key: 'toast',
      severity: 'success',
      closable: true,
    })
  }

  warn({ detail, summary }) {
    this.messageService.add({
      detail,
      summary,
      key: 'toast',
      severity: 'warn',
      closable: true,
    })
  }

  error({ detail, summary }) {
    this.messageService.add({
      detail,
      summary,
      key: 'toast',
      severity: 'error',
      closable: true,
    })
  }

}
