import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user;
  private authenticationSubject: BehaviorSubject<any>;

  constructor() {
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  get isAdmin() {
    return false;
  }

  get isUser() {
    return false;
  }

  get isSignedIn() {
    return false;
  }

  public currentAuthenticatedUser() {
    return Auth.currentAuthenticatedUser();
  }

  public signIn(email: string, password: string): Promise<any> {
    return Auth.signIn(email, password)
  }

  public confirmSignIn(user: any, code: string) {
    return Auth.confirmSignIn(user, code, 'SMS_MFA')
  }

  public signUp(email: string, password: string): Promise<any> {
    return Auth.signUp({ username: email, password })
  }

  public completeNewPassword(user: any, newPassword: string) {
    return Auth.completeNewPassword(user, newPassword);
  }

  public confirmSignUp(email: string, code: string): Promise<any> {
    return Auth.confirmSignUp(email, code);
  }

  public forgotPassword(email: string) {
    return Auth.forgotPassword(email)
  }

  public forgotPasswordSubmit(email: string, code: string, password: string) {
    return Auth.forgotPasswordSubmit(email, code, password)
  }

  public signOut(): Promise<any> {
    return Auth.signOut().then(() => { this.authenticationSubject.next(false) });
  }
}
