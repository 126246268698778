import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { MessageService } from 'primeng/api';
import { AuthState } from './models/auth-state.enum';
import { ROUTES } from './routes';
import { environment } from 'src/environments/environment';
import Amplify from 'aws-amplify';

@Component({
  selector: 'app-auth',
  providers: [ MessageService ],
  template: `
    <span *ngIf="state === AuthState.signIn">
      <div class="form-container">
        <span class="p-input-icon-left"><i class="pi pi-envelope"></i>
          <input [(ngModel)]="email" placeholder="Email" type="text" autocomplete="off" pInputText>
        </span>
        <span class="p-input-icon-left"><i class="pi pi-key"></i>
          <input [(ngModel)]="password" placeholder="Password" type="password" autocomplete="off" pInputText>
        </span>
        <span *ngIf="showNewPassword" class="p-input-icon-left"><i class="pi pi-key"></i>
          <input [(ngModel)]="newPassword" placeholder="New password" type="password" autocomplete="off" pInputText>
        </span>
        <span *ngIf="showSMSCode" class="p-input-icon-left"><i class="pi pi-phone"></i>
          <input [(ngModel)]="SMSCode" placeholder="SMS code" autocomplete="off" pInputText>
        </span>
        <a href="#" class="p-d-flex" (click)="state = AuthState.forgotPassword">Forgot your password?</a>
      </div>
      <div class="button-container">
        <div class="p-field"><div class="p-field-checkbox cursor-pointer"><p-inputSwitch [(ngModel)]="isAdmin" (onChange)="changeCognito($event)" inputId="admin"></p-inputSwitch><label for="admin">Admin</label></div></div>
        <button (click)="clickSignIn()" label="Sign In" [loading]="loading" type="button" pButton></button>
        <span class="p-mt-2">Don’t have an account?<a (click)="state = AuthState.signUp">Sign-up here</a></span>
        <span class="p-mt-2">Have a verification code?<a (click)="state = AuthState.confirmSignUp">Confirm sign-up</a></span>
      </div>
    </span>
    <span *ngIf="state === AuthState.signUp">
      <div class="form-container">
        <span class="p-input-icon-left"><i class="pi pi-envelope"></i>
          <input [(ngModel)]="email" placeholder="Email" type="text" autocomplete="off" pInputText>
        </span>
        <span class="p-input-icon-left"><i class="pi pi-key"></i>
          <input [(ngModel)]="password" placeholder="New password" type="password" autocomplete="off" pInputText>
        </span>
        <span class="p-input-icon-left"><i class="pi pi-key"></i>
          <input [(ngModel)]="repeatPassword" [class]="password !== repeatPassword ? 'ng-dirty ng-invalid' : ''" placeholder="Repeat password" type="password" autocomplete="off" pInputText>
        </span>
      </div>
      <div class="button-container"><br>
        <div class="p-field"><div class="p-field-checkbox cursor-pointer"><p-inputSwitch [(ngModel)]="isAdmin" (onChange)="changeCognito($event)" inputId="admin"></p-inputSwitch><label for="admin">Admin</label></div></div>
        <button [disabled]="password !== repeatPassword" (click)="signUp()" label="Sign Up" [loading]="loading" type="button" pButton></button>
        <span class="p-mt-2">Have an account?<a (click)="state = AuthState.signIn">Sign-in here</a></span>
        <span class="p-mt-2">Have a verification code?<a (click)="state = AuthState.confirmSignUp">Confirm sign-up</a></span>
      </div>
    </span>
    <span *ngIf="state === AuthState.confirmSignUp">
      <div class="form-container">
        <span class="p-input-icon-left"><i class="pi pi-envelope"></i>
          <input [(ngModel)]="email" placeholder="Email" type="text" autocomplete="off" pInputText>
        </span>
        <span class="p-input-icon-left"><i class="pi pi-key"></i>
          <input [(ngModel)]="code" placeholder="Verification code" type="text" autocomplete="off" pInputText>
        </span>
      </div>
      <div class="button-container"><br>
        <div class="p-field"><div class="p-field-checkbox cursor-pointer"><p-inputSwitch [(ngModel)]="isAdmin" (onChange)="changeCognito($event)" inputId="admin"></p-inputSwitch><label for="admin">Admin</label></div></div>
        <button (click)="confirmSignUp()" label="Confirm Sign Up" [loading]="loading" type="button" pButton></button>
        <span class="p-mt-2">Have an account?<a (click)="state = AuthState.signIn">Sign-in here</a></span>
        <span class="p-mt-2">Don’t have an account?<a (click)="state = AuthState.signUp">Sign-up here</a></span>
      </div>
    </span>
    <span *ngIf="state === AuthState.forgotPassword">
      <div class="form-container">
        <span class="p-input-icon-left"><i class="pi pi-envelope"></i>
          <input [(ngModel)]="email" placeholder="Email" type="text" autocomplete="off" pInputText>
        </span>
      </div>
      <div class="button-container"><br>
        <div class="p-field"><div class="p-field-checkbox cursor-pointer"><p-inputSwitch [(ngModel)]="isAdmin" (onChange)="changeCognito($event)" inputId="admin"></p-inputSwitch><label for="admin">Admin</label></div></div>
        <button (click)="forgotPassword()" label="Forgot Password" [loading]="loading" type="button" pButton></button>
        <span class="p-mt-2">Have an account?<a (click)="state = AuthState.signIn">Sign-in here</a></span>
        <span class="p-mt-2">Don’t have an account?<a (click)="state = AuthState.signUp">Sign-up here</a></span>
      </div>
    </span>
    <span *ngIf="state === AuthState.forgotPasswordSubmit">
      <div class="form-container">
        <span class="p-input-icon-left"><i class="pi pi-envelope"></i>
          <input [(ngModel)]="email" placeholder="Email" type="text" autocomplete="off" pInputText>
        </span>
        <span class="p-input-icon-left"><i class="pi pi-key"></i>
          <input [(ngModel)]="code" placeholder="Verification code" type="text" autocomplete="off" pInputText>
        </span>
        <span class="p-input-icon-left"><i class="pi pi-key"></i>
          <input [(ngModel)]="password" placeholder="New password" type="password" autocomplete="off" pInputText>
        </span>
      </div>
      <div class="button-container"><br>
        <div class="p-field"><div class="p-field-checkbox cursor-pointer"><p-inputSwitch [(ngModel)]="isAdmin" (onChange)="changeCognito($event)" inputId="admin"></p-inputSwitch><label for="admin">Admin</label></div></div>
        <button (click)="forgotPasswordSubmit()" label="Change password" [loading]="loading" type="button" pButton></button>
        <span class="p-mt-2">Have an account?<a (click)="state = AuthState.signIn">Sign-in here</a></span>
        <span class="p-mt-2">Don’t have an account?<a (click)="state = AuthState.signUp">Sign-up here</a></span>
      </div>
    </span>
    <p-toast position="bottom-right"></p-toast>
  `,
})
export class AuthComponent implements OnInit {
  isAdmin = false
  loading = false
  showNewPassword = false
  showSMSCode = false
  email = ""
  password = ""
  newPassword = ""
  repeatPassword = ""
  SMSCode = ""
  code = ""
  user = null
  state = AuthState.signIn
  AuthState = AuthState

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  proceed() {
    const groups = this.user.signInUserSession.idToken.payload['cognito:groups'];
    if (!groups) {
      throw new Error('No access for this account, ask admins for help.')
    }
    if (groups.includes('user-admins') || groups.includes('users')) {
      this.router.navigate([ROUTES.USER_LICENSES])
    } else if (groups.includes('admins')) {
      this.router.navigate([ROUTES.ADMIN_COMPANIES])
    } else {
      throw new Error('No access for this account, ask admins for help.')
    }
  }

  clickSignIn() {
    if (this.showNewPassword) return this.completeNewPassword();
    if (this.showSMSCode) return this.confirmSignIn();
    this.signIn();
  }

  signIn() {
    this.loading = true;
    this.authService.signIn(this.email, this.password)
      .then(user => {
        this.user = user;
        if (user.challengeName === 'SMS_MFA') {
          this.loading = false;
          this.showSMSCode = true;
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.loading = false;
          this.showNewPassword = true;
        } else {
          this.proceed();
        }
      })
      .catch(err => this.messageService.add({ detail: err.message, severity: 'error', summary: 'Error' }))
      .finally(() => this.loading = false);
  }

  confirmSignIn() {
    this.loading = true;
    this.authService.confirmSignIn(this.user, this.SMSCode)
      .then(user => {
        this.user = user;
        this.proceed();
      })
      .catch(err => this.messageService.add({ detail: err.message, severity: 'error', summary: 'Error' }))
      .finally(() => this.loading = false);
  }

  signUp() {
    this.loading = true;
    this.authService.signUp(this.email, this.password)
      .then(() => this.state = AuthState.confirmSignUp)
      .catch(err => this.messageService.add({ detail: err.message, severity: 'error', summary: 'Error' }))
      .finally(() => this.loading = false);
  }

  completeNewPassword() {
    this.loading = true;
    this.authService.completeNewPassword(this.user, this.newPassword)
      .then(user => {
        this.user = user;
        if (user.challengeName === 'SMS_MFA') {
          this.loading = false;
          this.showSMSCode = true;
        } else {
          this.proceed();
        }
      })
      .catch(err => this.messageService.add({ detail: err.message, severity: 'error', summary: 'Error' }))
      .finally(() => this.loading = false);
  }

  confirmSignUp() {
    this.loading = true;
    this.authService.confirmSignUp(this.email, this.code)
      .then(res => this.state = AuthState.signIn)
      .catch(err => this.messageService.add({ detail: err.message, severity: 'error', summary: 'Error' }))
      .finally(() => this.loading = false);
  }

  forgotPassword() {
    this.loading = true;
    this.authService.forgotPassword(this.email)
      .then(() => this.state = AuthState.forgotPasswordSubmit)
      .catch(err => this.messageService.add({ detail: err.message, severity: 'error', summary: 'Error' }))
      .finally(() => this.loading = false);
  }

  forgotPasswordSubmit() {
    this.loading = true;
    this.authService.forgotPasswordSubmit(this.email, this.code, this.password)
      .then(() => this.state = AuthState.signIn)
      .catch(err => this.messageService.add({ detail: err.message, severity: 'error', summary: 'Error' }))
      .finally(() => this.loading = false);
  }

  changeCognito(event) {
    if (event.checked) {
      localStorage.setItem('isAdmin', 'true')
      environment.AWS = environment.ADMIN;
    } else {
      localStorage.removeItem('isAdmin')
      environment.AWS = environment.USER;
    }
    Amplify.configure(environment.AWS)
  }
}
