import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { License, licenseMap } from './models/license';
import { LicenseState } from './models/license-state.enum';
import { ModalType } from './models/modal-type.enum';
import { Company } from './models/company';
import { ModalService } from './modal/modal.service';
import { LicenseService } from './services/license.service';

@Component({
  selector: 'app-table-license',
  template: `
    <p-table
      #licenseTable
      [value]="licenses"
      dataKey="license_sn"
      [loading]="loading"
      [globalFilterFields]="globalFilterFields">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <button pButton *ngIf="isAdding" (click)="licenseAdd()">Add license</button>
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="licenseTable.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3.5rem"></th>
          <th style="min-width: 8rem" pSortableColumn="client.organizational_unit_OU">Instance SN <p-sortIcon field="client.organizational_unit_OU"></p-sortIcon></th>
          <th style="min-width: 14rem" pSortableColumn="license_sn">License ID <p-sortIcon field="license_sn"></p-sortIcon></th>
          <th style="width: 12rem" pSortableColumn="updated">Updated <p-sortIcon field="updated"></p-sortIcon></th>
          <th style="width: 7rem" pSortableColumn="state">State <p-sortIcon field="state"></p-sortIcon></th>
          <th style="width: 10rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5" class="p-p-6">There are no license for this company yet.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="5" class="p-p-6">Fetching licenses data...</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-license let-expanded="expanded">
        <tr>
          <td>
            <button
              pButton pRipple type="button"
              [pRowToggler]="license"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>{{license.client ? license.client.organizational_unit_OU : "Waiting for license request"}}</td>
          <td>{{license.license_sn}}</td>
          <td>{{license.updated ? license.updated.split('.')[0].replace('T', ' ') : 'none' }}</td>
          <td>{{license.state}}</td>
          <td *ngIf="isAdmin" class="p-text-right">
            <button
              icon="pi pi-check-circle"
              pTooltip="Commit license"
              (click)="modalService.subject.next({ type: type.COMMIT, license: license })"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-success p-button-outlined p-mr-2"></button>
            <button
              icon="pi pi-pencil"
              pTooltip="Edit license"
              (click)="modalService.subject.next({ type: type.LICENSE, license: license })"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-outlined p-mr-2"></button>
            <button
              *ngIf="license.state === state.TEMPLATE"
              icon="pi pi-trash"
              pTooltip="Remove license"
              (click)="modalService.subject.next({ type: type.LICENSE_REMOVE, license: license })"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-danger p-button-outlined p-mr-2"></button>
            <button
              *ngIf="license.state != state.TEMPLATE"
              icon="pi pi-ban"
              pTooltip="Disable license"
              (click)="modalService.subject.next({ type: type.LICENSE_DISABLE, license: license })"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-danger p-button-outlined p-mr-2"></button>
          </td>
          <td *ngIf="!isAdmin" class="p-text-right">
            <button *ngIf="license.state === state.TEMPLATE || license.state === state.REJECTED"
              icon="pi pi-briefcase"
              pTooltip="Required information to create license request"
              (click)="modalService.subject.next({ type: type.COMPANY_INFO, license: license })"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-outlined p-mr-2"></button>
            <button *ngIf="license.state === state.TEMPLATE || license.state === state.REJECTED"
              icon="pi pi-upload"
              pTooltip="Upload license request"
              (click)="modalService.subject.next({ type: type.REQUEST, license: license })"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-success p-button-outlined p-mr-2"></button>
            <button *ngIf="license.state === state.ACTIVATED"
              icon="pi pi-download"
              pTooltip="Download activation token"
              (click)="modalService.subject.next({ type: type.TOKEN, license: license })"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-success p-button-outlined p-mr-2"></button>
            <button *ngIf="license.state === state.DISABLED"
              icon="pi pi-ban"
              pTooltip="License disabled"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-danger p-button-outlined p-mr-2"></button>
            <button *ngIf="license.state === state.WAIT_FOR_ADMIN"
              icon="pi pi-clock"
              pTooltip="Wait for admin"
              pButton pRipple type="button" tooltipPosition="bottom" class="p-button-danger p-button-outlined p-mr-2"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-license>
        <tr>
          <td></td>
          <td style="padding: 2rem .5rem 4rem; vertical-align: top" colspan="1">
            <p-table [value]="map">
              <ng-template pTemplate="caption">
                License
              </ng-template>
              <ng-template pTemplate="body" let-map>
                <tr>
                  <td style="width: 9rem">{{ map.label }}</td>
                  <td>{{ getValue(license[map.value]) }}</td>
                </tr>
              </ng-template>
            </p-table>
          </td>
          <td style="padding: 2rem .5rem 4rem; vertical-align: top" colspan="4">
            <p-table
              *ngFor="let module of license.application_modules"
              [value]="module.enabled_functions">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 14rem">{{ module.module_def }}</th>
                  <th>Name</th>
                  <th>Valid from</th>
                  <th>Valid to</th>
                  <th>Trial</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-function>
                <tr>
                  <td>{{ licenseService.lic2price(license, function) }}</td>
                  <td>{{ function.name }}</td>
                  <td>{{ function.valid_from.join('/') }}</td>
                  <td>{{ function.valid_to.join('/') }}</td>
                  <td>{{ function.trial ? 'true' : 'false' }}</td>
                </tr>
              </ng-template>
            </p-table>
          </td>
        </tr>
      </ng-template>
    </p-table>
  `
})
export class TableLicenseComponent {
  @Input() isAdmin: boolean;
  @Input() isAdding: boolean;
  @Input() company?: Company;
  @Input() loading: boolean;
  @Input() licenses: License[];
  map = licenseMap;
  type = ModalType;
  state = LicenseState;
  globalFilterFields = [
    'client.organizational_unit_OU',
    'license_sn',
    'updated',
    'state',
    'client_access_key',
    'client_access_policy_arn',
    'client_admin_email',
    'client_type',
    'company_id',
    'description',
    'issuer_signature',
    'size',
    'type',
  ];

  constructor(
    public licenseService: LicenseService,
    private modalService: ModalService,
  ) { }

  licenseAdd() {
    this.licenseService.add(this.company.id).then(license => {
      this.modalService.subject.next({ type: ModalType.LICENSE, license })
    })
  }

  getValue(value) {
    if (typeof value === 'boolean') {
      return value.toString()
    }
    return value || 'none'
  }
}
