import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Company } from '../models/company';
import { ModalType } from '../models/modal-type.enum';
import { CompanyService } from '../services/company.service';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal-company-info',
  template: `
    <p-dialog
      header="Company info"
      [modal]="true"
      *ngIf="visible"
      [style]="{width: '320px'}"
      [(visible)]="visible">
      <div class="p-fluid">
        <div class="p-grid">
          <div class="p-col">
            <div class="p-field">
              <label>Admin email</label>
              <span class="p-input-icon-right">
                <i class="pi pi-copy" pTooltip="copy to clip"></i>
                <input (click)="copy($event)" style="cursor: pointer" pInputText readonly [ngModel]="company.client_admin_email">
              </span>
            </div>
            <div class="p-field">
              <label>Common name</label>
              <span class="p-input-icon-right">
                <i class="pi pi-copy" pTooltip="copy to clip"></i>
                <input (click)="copy($event)" style="cursor: pointer" pInputText readonly [ngModel]="company.name">
              </span>
            </div>
            <div class="p-field">
              <label>Organization</label>
              <span class="p-input-icon-right">
                <i class="pi pi-copy" pTooltip="copy to clip"></i>
                <input (click)="copy($event)" style="cursor: pointer" pInputText readonly [ngModel]="company.id">
              </span>
            </div>
            <div class="p-field">
              <label>Locality</label>
              <span class="p-input-icon-right">
                <i class="pi pi-copy" pTooltip="copy to clip"></i>
                <input (click)="copy($event)" style="cursor: pointer" pInputText readonly [ngModel]="company.city">
              </span>
            </div>
            <div class="p-field">
              <label>State or province</label>
              <span class="p-input-icon-right">
                <i class="pi pi-copy" pTooltip="copy to clip"></i>
               <input (click)="copy($event)" style="cursor: pointer" pInputText readonly [ngModel]="company.state_or_province">
              </span>
            </div>
            <div class="p-field">
              <label>Country</label>
              <span class="p-input-icon-right">
                <i class="pi pi-copy" pTooltip="copy to clip"></i>
                <input (click)="copy($event)" style="cursor: pointer" pInputText readonly [ngModel]="company.country">
              </span>
            </div>
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <button (click)="download()" label="Download info" type="button" pButton></button>
        <button (click)="visible = false" label="Close" type="button" pButton class="p-button-secondary"></button>
      </ng-template>
    </p-dialog>
  `
})
export class ModalCompanyInfoComponent implements OnInit {
  sub: Subscription;
  visible: boolean;
  company: Company;
  loading = false

  constructor(
    private companyService: CompanyService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.sub = this.modalService.subject.subscribe(res => {
      if (res.type === ModalType.COMPANY_INFO) {
        this.company = this.companyService.company;
        this.visible = true;
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  copy(event) {
    navigator.clipboard.writeText(event.target.value)
    this.modalService.success({ summary: 'Success!', detail: 'Copied to cliboard!' })
  }

  download() {
    download(
      "company.txt",
`Admin email:       ${this.company.client_admin_email}
Common name:       ${this.company.name}
Organization:      ${this.company.id}
Locality:          ${this.company.city}
State or province: ${this.company.state_or_province}
Country:           ${this.company.country}
`
    );
  }
}

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
