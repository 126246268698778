export enum ModalType {
  LICENSE,
  LICENSE_DISABLE,
  LICENSE_REMOVE,

  COMPANY_INFO,
  COMPANY_ADD,
  COMPANY_EDIT,
  COMPANY_ACTIVATE,
  COMPANY_DEACTIVATE,

  MODULE,
  FUNCTION,

  COMMIT,
  TOKEN,
  REQUEST,

  USER_ADD,
  USER_REMOVE,
}
