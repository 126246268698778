import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { AppFile } from '../models/app-file';
import { FilesService } from '../services/files.service';

@Component({
  selector: 'app-user-files',
  template: `
    <p-table
      #fileTable
      [value]="fileService.files"
      dataKey="Key"
      [globalFilterFields]="['Key', 'Size']"
      [loading]="fileService.loading">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <h4>Files</h4>
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="fileTable.filterGlobal($event.target.value, 'contains')"
              placeholder="Search keyword" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Filename</th>
          <th>Size</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-file>
        <tr>
          <td>{{file.Key}}</td>
          <td>{{file.Size}}</td>
          <td class="p-text-right">
            <button
              [disabled]="loading"
              pButton pRipple class="p-button-outlined p-mr-2" type="button" tooltipPosition="bottom"
              icon="pi pi-copy"
              pTooltip="Copy URL to clipboard"
              (click)="copyLink(file)"></button>
            <button
              [disabled]="loading"
              pButton pRipple class="p-button-success p-button-outlined p-mr-2" type="button" tooltipPosition="bottom"
              icon="pi pi-download"
              pTooltip="Download file"
              (click)="download(file)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3" class="p-p-6">There are no files...</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <tr>
          <td colspan="3" class="p-p-6">Fetching files...</td>
        </tr>
      </ng-template>
    </p-table>
  `,
})
export class UserFilesComponent implements OnInit {
  loading = false;

  constructor(
    public fileService: FilesService,
    private modalSerice: ModalService,
  ) { }

  ngOnInit(): void {
    this.fileService.get()
  }

  download(file: AppFile) {
    this.modalSerice.info({ detail: 'Download in progress...', summary: 'Start downloading' })
    this.loading = true;
    this.fileService.presigned(file.Key).then(res => {
      fetch(res.output).then(t => {
        t.blob().then(b => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', file.Key);
          a.addEventListener('click', () => this.loading = false, false)
          a.click();
        });
      });
    })
    .catch(() => this.loading = false);
  }

  copyLink(file: AppFile) {
    this.loading = true;
    this.fileService.presigned(file.Key).then(res => {
    return navigator.clipboard.writeText(res.output)
      .then(() => this.modalSerice.success({ detail: 'Link copied to clipboard', summary: 'Success!' }))
      .catch(err => this.modalSerice.error({ detail: 'Unexpected error', summary: 'Error' }))
    })
    .finally(() => this.loading = false)

  }
}
