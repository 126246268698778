import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

import { ModalCommitComponent } from "./modal-commit.component";
import { ModalCompanyComponent } from './modal-company.component';
import { LicenseModalComponent } from './modal-license.component';
import { ModalsComponent } from './modal.component';
import { ModalModuleComponent } from './modal-module.component';
import { ModalRequestComponent } from './modal-request.component';
import { ModalTokenComponent } from './modal-token.component';
import { ModalFunctionComponent } from './modal-function.component';
import { ModalUserComponent } from './modal-user.component';
import { ModalCompanyInfoComponent } from './modal-company-info.component';

@NgModule({
  declarations: [
    ModalCommitComponent,
    ModalCompanyComponent,
    LicenseModalComponent,
    ModalsComponent,
    ModalModuleComponent,
    ModalRequestComponent,
    ModalTokenComponent,
    ModalFunctionComponent,
    ModalUserComponent,
    ModalCompanyInfoComponent,
  ],
  imports: [
    // Angular imports
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // PrimeNg imports
    ButtonModule,
    CalendarModule,
    ConfirmDialogModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    TableModule,
    ToastModule,
  ],
  exports: [
    ModalsComponent,
  ],
  providers: [
  ]
})
export class ModalModule { }
