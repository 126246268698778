export enum ClientType {
  COMMERCIAL = "COMMERCIAL",
  NFR        = "NFR",
  EDU        = "EDU",
  GOV        = "GOV",
  TRIAL      = "TRIAL",
}

export const clientTypeMap = {
  [ClientType.COMMERCIAL]: "COM",
  [ClientType.NFR]:        "NFR",
  [ClientType.EDU]:        "EDU",
  [ClientType.GOV]:        "GOV",
  [ClientType.TRIAL]:      "TRI",
}
