export enum LicenseSize {
  BASIC = "BASIC",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  CUSTOM = "CUSTOM",
}

export const licenseSizeMap = {
  [LicenseSize.BASIC]: "B",
  [LicenseSize.SMALL]: "S",
  [LicenseSize.MEDIUM]: "M",
  [LicenseSize.LARGE]: "L",
  [LicenseSize.CUSTOM]: "C",
}
