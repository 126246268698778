import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Company } from '../models/company';
import { License } from '../models/license';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  loading = false;
  company: Company;
  companies: Company[] = [];

  constructor(private APIService: APIService) { }

  async get(force = false): Promise<Company[]> {
    if (force || (this.companies.length === 0 && !this.loading)) {
      this.loading = true;
      this.companies = await this.APIService.get(this.APIService.URL.COMPANY) as Company[];
      this.loading = false;
    }
    return this.companies
  }

  async getOne(): Promise<Company> {
    this.company = await this.APIService.get(this.APIService.URL.COMPANY_INFO) as Company;
    return this.company;
  }

  findByLicense(license: License): Company {
    return this.companies.find(company => company.id === license.company_id);
  }

  async add(company: Company): Promise<Company[]> {
    await this.APIService.post(this.APIService.URL.COMPANY, company) as Promise<string>;
    return await this.get(true);
  }

  async update(company: Company): Promise<Company[]> {
    const companyId = company.id;
    delete company.id;
    await this.APIService.put(this.APIService.URL.COMPANY_EDIT, company, { companyId }) as Promise<string>;
    return await this.get(true);
  }

  async activate(companyId: string, body: { valid_to: string }): Promise<Company[]> {
    await this.APIService.put(this.APIService.URL.COMPANY_ACTIVATE, body, { companyId });
    return await this.get(true);
  }

  async deactivate(companyId: string): Promise<Company[]> {
    await this.APIService.put(this.APIService.URL.COMPANY_DEACTIVATE, {}, { companyId })
    return await this.get(true);
  }
}
