import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ModalType } from '../models/modal-type.enum';
import { CompanyService } from '../services/company.service';
import { LicenseService } from '../services/license.service';
import { UsersService } from '../services/users.service';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modals',
  providers: [ ConfirmationService ],
  template: `
    <app-license-modal></app-license-modal>
    <app-modal-commit></app-modal-commit>
    <app-modal-company></app-modal-company>
    <app-modal-company-info></app-modal-company-info>
    <app-modal-function></app-modal-function>
    <app-modal-token></app-modal-token>
    <app-modal-request></app-modal-request>
    <app-modal-user></app-modal-user>
    <p-toast key="toast" position="bottom-right"></p-toast>
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
  `,
  styles: [
  ]
})
export class ModalsComponent implements OnInit, OnDestroy {
  sub: Subscription

  constructor(
    private userService: UsersService,
    private modalService: ModalService,
    private companyService: CompanyService,
    private licenseService: LicenseService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.sub = this.modalService.subject.subscribe(res => {
      if (res.type === ModalType.COMPANY_ACTIVATE) {
        const company = res.company
        this.confirmationService.confirm({
          message: 'This action will activate all company users, are you sure that you want to proceed?',
          accept: () => this.companyService.activate(company.id, { valid_to: '2022.12.12' }),
        });
      }

      if (res.type === ModalType.COMPANY_DEACTIVATE) {
        const company = res.company
        this.confirmationService.confirm({
          message: 'This action will deactivate all company users and disable all assigned licenses',
          accept: () => this.companyService.deactivate(company.id),
        });
      }

      if (res.type === ModalType.LICENSE_REMOVE) {
        const license = res.license
        this.confirmationService.confirm({
          message: 'This action will remove license, are you sure that you want to proceed?',
          accept: () => this.licenseService.remove(license.license_sn)
        });
      }

      if (res.type === ModalType.LICENSE_DISABLE) {
        const license = res.license
        this.confirmationService.confirm({
          message: 'This action will disable license, are you sure that you want to proceed?',
          accept: () => this.licenseService.disable(license.license_sn)
        });
      }

      if (res.type === ModalType.USER_REMOVE) {
        const user = res.user;
        this.confirmationService.confirm({
          message: 'This action will delete user, are you sure that you want to proceed?',
          accept: () => this.userService.remove(user.clientId)
        });
      }

    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}
