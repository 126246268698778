import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from './modal.service';
import { ModalType } from '../models/modal-type.enum';
import { LicenseService } from '../services/license.service';

@Component({
  selector: 'app-modal-token',
  template: `
    <p-dialog
      header="Activation token"
      [modal]="true"
      *ngIf="visible"
      [(visible)]="visible">
      <div class="p-fluid">
        <div class="p-grid">
          <div class="p-col">
            <div class="p-field">
              <textarea
                [rows]="18"
                [cols]="65"
                pInputTextarea
                style="font-family: 'Courier New', Courier, monospace"
                placeholder="Loading..."
                [(ngModel)]="token"></textarea>
            </div>
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <button pButton (click)="visible = false" class="p-button-secondary">Close</button>
      </ng-template>
    </p-dialog>
  `
})
export class ModalTokenComponent implements OnInit, OnDestroy {
  sub: Subscription
  visible = false
  token = ""

  constructor(
    private modalService: ModalService,
    private licenseService: LicenseService,
  ) { }

  ngOnInit(): void {
    this.sub = this.modalService.subject.subscribe(res => {
      if (res.type === ModalType.TOKEN) {
        this.token = "";
        this.visible = true;
        this.licenseService.token(res.license.license_sn)
          .then(res => this.token = res)
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}
