import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalType } from '../models/modal-type.enum';
import { UsersService } from '../services/users.service';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal-user',
  template: `
    <p-dialog
      header="Add user"
      [modal]="true"
      *ngIf="visible"
      [(visible)]="visible">
      <div class="p-fluid">
        <div class="p-field" style="width: 250px">
          <input
            pInputText
            [(ngModel)]="email"
            placeholder="User e-mail"
          />
        </div>
      </div>
      <ng-template pTemplate="footer">
        <button pButton (click)="visible = false" class="p-button-secondary">Close</button>
        <button pButton autofocus (click)="userAdd()">Add user</button>
      </ng-template>
    </p-dialog>
  `
})
export class ModalUserComponent implements OnInit {
  sub: Subscription
  visible = false;
  email = ""

  constructor(
    private userService: UsersService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.sub = this.modalService.subject.subscribe(res => {
      if (res.type === ModalType.USER_ADD) {
        this.email = "";
        this.visible = true;
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  userAdd() {
    this.userService.add(this.email)
      .then(() => this.visible = false);
  }

}
