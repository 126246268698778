import { Injectable } from '@angular/core';
import { AppFile } from '../models/app-file';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  files: AppFile[]
  loading: boolean

  constructor(private APIService: APIService) { }

  async get() {
    this.loading = true;
    const res = await this.APIService.get(this.APIService.URL.FILE_GET) as {
      message: string
      output: AppFile[]
      statusCode: number
    };
    this.files = res.output;
    this.loading = false;
    return this.files;
  }

  async presigned(filename: string): Promise<{
    message: string
    output: string
    statusCode: number
  }> {
    return this.APIService.post(this.APIService.URL.FILE_PRESIGNED, { filename }) as any
  }
}
