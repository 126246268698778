export interface Client {
  admin_email: string
  country_C: string
  state_or_province_ST: string
  locality_L: string
  organization_O: string
  organizational_unit_OU: string
  common_name_CN: string
  type: string | null
}

export const clientInit = {
  admin_email: "",
  country_C: "",
  state_or_province_ST: "",
  locality_L: "",
  organization_O: "",
  organizational_unit_OU: "",
  common_name_CN: "",
  type: "",
} as Client;
