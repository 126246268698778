import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {SidebarModule} from 'primeng/sidebar';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';

import { MessageService } from 'primeng/api';

// Application Components
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
// import {AppConfigComponent} from './app.config.component';
import {AppInlineMenuComponent} from './app.inlinemenu.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopbarComponent} from './app.topbar.component';
// import {AppFooterComponent} from './app.footer.component';

// Demo pages
import {AppLoginComponent} from './pages/app.login.component';

// Application services
import {MenuService} from './app.menu.service';
// Custom imports
import { AdminCompaniesComponent } from './app/admin/admin-companies.component';
import { AdminLicensesComponent } from './app/admin/admin-licenses.component';
import { AuthComponent } from './app/auth.component';
import { UserLicensesComponent } from './app/user/user-licenses.component';
import { TableUserComponent } from './app/table-user.component';
import { TableLicenseComponent } from './app/table-license.component';
import { ModalModule } from './app/modal/modal.module';
import { UserFilesComponent } from './app/user/user-files.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    DropdownModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    SidebarModule,
    StepsModule,
    TableModule,
    TooltipModule,
    ToastModule,
    // custom modules
    ModalModule,
  ],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    // AppConfigComponent,
    AppInlineMenuComponent,
    AppTopbarComponent,
    // AppFooterComponent,
    AppLoginComponent,

    AdminCompaniesComponent,
    AuthComponent,
    UserLicensesComponent,
    TableUserComponent,
    TableLicenseComponent,
    AdminLicensesComponent,
    UserFilesComponent,
  ],
  providers: [
    MessageService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    MenuService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
